import { createContext, ReactNode } from 'react';
import type { AppContextType, Projects } from '../@types/AppContextTypes';

// create context provider
export const AppContext = createContext<AppContextType>({});

export const AppProvider = ({ children }: { children: ReactNode }) => {
  return <AppContext.Provider value={AppData}>{children}</AppContext.Provider>;
};

const recentProjects: Projects[] = [
  {
    githubUrl: 'https://github.com/SergioHPassos/django-vue-ecommerce',
    techList: ['vue', 'django', 'bulma'],
  },
  {
    githubUrl: 'https://github.com/SergioHPassos/bug-tracker',
    techList: ['reactjs', 'django', 'postgres'],
  },
  {
    githubUrl: 'https://github.com/SergioHPassos/FizzBuzz',
    techList: ['C++'],
  },
];

const recentExperience: Projects[] = [
  {
    githubUrl: 'https://www.herlanderart.com',
    techList: ['react', 'tailwind', 'typescript', 'AWS'],
  },
  {
    githubUrl: 'https://www.abatednj.com',
    techList: ['react', 'tailwind', 'typescript', 'AWS'],
  },
  {
    githubUrl: 'https://www.marthaslunch.com',
    techList: ['react', 'tailwind', 'typescript', 'Square SDK', 'AWS'],
  },
];

const AppData: AppContextType = {
  fullName: 'Sergio H. Passos',
  recentTech: [
    'java',
    'angular',
    'spring',
    'jdbc',
    'prisma',
    'mysql',
    'javascript',
    'express',
    'nodeJS',
    'typeScript',
    'aws services',
    'python',
    'docker',
    'tailwind',
    'react',
  ],
  recentExperience: recentExperience,
  recentProjects: recentProjects,
  linkedinUrl: 'https://www.linkedin.com/in/sergio-passos-809aa114a/',
  githubUrl: 'https://github.com/SergioHPassos',
  steamUrl: 'https://steamcommunity.com/profiles/76561198033129845/',
};
