/* eslint-disable jsx-a11y/anchor-is-valid */
// react stuff
// import { useState } from 'react';

// components
// import AboutMe from './components/AboutMe';
// import Clients from './components/Clients';
// import Footer from './components/Footer';
// import Header from './components/Header';
// import MyPicture from './components/MyPicture';
// import RecentProjects from './components/RecentProjects';
// import RecentTech from './components/RecentTech';
import UnderConstruction from './components/UnderConstruction';

function App() {
  // const [showMenu, setShowMenu] = useState<boolean>(false);

  return (
    <div
      className="h-screen w-screen overflow-scroll"
      style={{ backgroundColor: '#D7F2FE' }}
    >
      <UnderConstruction />
    </div>
  );
}

export default App;
