import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background-color: #fed7d7;
  border-radius: 15px;
  padding: 20px;
  text-align: center;
  width: 80%;
  margin: auto;
  margin-top: 20%;
  font-family: sans-serif;
  font-weight: bold;

  @media (min-width: 768px) {
    width: 30%;
  }
`;

const UnderConstruction: React.FC = () => {
  return (
    <Container>
      <h1 style={{ color: '#9F7AEA' }}>Under Construction</h1>
      <p style={{ color: '#9F7AEA' }}>We'll be back soon!</p>
      <span role="img" aria-label="construction" style={{ fontSize: '50px' }}>
        🚧
      </span>
    </Container>
  );
};

export default UnderConstruction;
